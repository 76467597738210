import { Article, Category } from '@wix/answers-api';
import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Collapse, Text } from '@wix/design-system';
import { DATA_HOOKS } from './../../../dataHooks';
import { CategoryArticles } from './CategoryArticles';
import { NavigationTopics } from '..';
import { ChevronRightIcon } from '../../../icons/ChevronRightIcon';
import { ChevronRightBoldIcon } from '../../../icons/ChevronRightBoldIcon';
import css from './index.module.scss';
import { InnerLink } from '../../InnerLink';
import { TOOLTIP_ENTER_DELAY } from '../../../constants';

export type NavCategoryNodeProps = {
  category: NavigationTopics;
  isRoot?: boolean;
  selectedCategoryId?: string;
  order: number;
  expandedCategories: string[];
  onCategoryChange: (category: Category, order: number) => Promise<void>;
  onArticleClick: (article: Article, order: number) => Promise<void>;
  onCategoryToggle: (category: Category, order: number) => void;
};

export const NavCategoryNode: FunctionComponent<NavCategoryNodeProps> = ({
  category,
  isRoot,
  selectedCategoryId,
  order,
  expandedCategories,
  onCategoryChange,
  onCategoryToggle,
  onArticleClick,
}) => {
  const isCategorySelected = selectedCategoryId === category.id;
  const hasChildren = category.children && category.children.length > 0;
  const [articles, setArticles] = useState<Article[] | undefined>(undefined);
  const isExpanded = expandedCategories.includes(category.id);
  const onKeyPress = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      e.currentTarget.click();
    }
  };

  const hasSubCategories = category.children && category.children.length > 0;
  const articlesPopulated = articles !== undefined;

  const onChevronClick = async () => {
    onCategoryToggle(category, order);
    if (!hasSubCategories && !articlesPopulated) {
      const categoryArticles = category.articles && (await category.articles());
      setArticles(categoryArticles || []);
    }
  };

  useEffect(() => {
    void (async () => {
      if (!hasSubCategories && !articlesPopulated && isCategorySelected) {
        const categoryArticles =
          category.articles && (await category.articles());
        setArticles(categoryArticles || []);
      }
    })();
  }, [isCategorySelected]);

  const categoryText = (
    <Text
      className={css.name}
      dataHook={`${DATA_HOOKS.NAV_CATEGORY_TREE_CATEGORY_NAME}-${category.id}`}
      size="small"
      weight="thin"
      ellipsis
      tooltipProps={{enterDelay: TOOLTIP_ENTER_DELAY}}
      maxLines={3}
    >
      {category.name}
    </Text>
  );
  return (
    <Box
      dataHook={`${DATA_HOOKS.NAV_CATEGORY_TREE_CATEGORY}-${category.id}`}
      key={category.id}
    >
      <ul role="list" key={category.id} className={`${css.CategoryItems}`}>
        <li role="listitem" className={`${css.categoryItem}`}>
          <div
            className={`${css.categoryItemName} ${
              isExpanded ? css.expanded : ''
            } ${isRoot ? css.root : ''} ${
              isCategorySelected ? css.selected : ''
            }`}
          >
            <div
              data-hook={`${DATA_HOOKS.NAV_CATEGORY_TREE_CATEGORY_CHEVRON}-${category.id}`}
              className={css.chevronWrapper}
              tabIndex={0}
              onKeyPress={onKeyPress}
              onClick={onChevronClick}
            >
              {isRoot ? <ChevronRightBoldIcon /> : <ChevronRightIcon />}
            </div>
            {!isCategorySelected ? (
              <InnerLink
                onClick={() => onCategoryChange(category, order)}
                href={category.uri}
                className={css.link}
                withEnterHandler
                shallow
              >
                {categoryText}
              </InnerLink>
            ) : (
              <>{categoryText}</>
            )}
          </div>
          <Collapse
            open={isExpanded}
            key={`collapse-${category.id}`}
            dataHook={`${DATA_HOOKS.NAV_CATEGORY_TREE_CATEGORY_COLLAPSE}`}
          >
            {hasChildren ? (
              category.children?.map((childCat, idx) => (
                <NavCategoryNode
                  order={idx + 1}
                  expandedCategories={expandedCategories}
                  category={childCat}
                  onArticleClick={onArticleClick}
                  key={childCat.id}
                  selectedCategoryId={selectedCategoryId}
                  onCategoryToggle={onCategoryToggle}
                  onCategoryChange={onCategoryChange}
                />
              ))
            ) : (
              <CategoryArticles
                onArticleClick={onArticleClick}
                categoryId={category.id}
                articles={articles}
                numberOfArticles={category.publishedArticleCount as number}
              />
            )}
          </Collapse>
        </li>
      </ul>
    </Box>
  );
};
